@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Comfortaa', cursive;
}


.sub-menu-desktop ul {
  padding: 0 !important ;
}


.box {
  position:absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(39 44 92 / 60%);
  z-index: 1000;
  transition: opacity 0.5s, z-index 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;

}

.fade-out {
  opacity: 0;
  z-index: -1000;
}


.rc-anchor-invisible, .grecaptcha-badge {
  opacity:0 !important;
}

.privacy-link{
  color: #eee;
  text-decoration: none;
}

.privacy-link:hover{
  text-decoration: underline;
}

ul{
  list-style: none;
  margin: 0;
  padding: 0;
}


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
